/* eslint-disable max-len */
import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';

import inkblotLogoDark from '../images/nav/inkblot-logo.svg';
import { SERVER_URL } from '../utils/environment';

const subprocessorData = [
  {
    name: 'Acquaint Financial Inc.',
    country: 'Canada',
    activities: 'Financial services vendor',
    personalData: 'Yes',
  },
  {
    name: 'Amazon Web Services, Inc.',
    country: 'Canada',
    activities: 'Cloud service provider',
    personalData: 'Yes',
  },
  {
    name: 'Assistenza International',
    country: 'Canada',
    activities: 'Legal services vendor',
    personalData: 'Yes',
  },
  {
    name: 'CircleCI',
    country: '',
    activities: 'Continuous integration',
    personalData: 'No',
  },
  {
    name: 'Github',
    country: '',
    activities: 'Source control management',
    personalData: 'No',
  },
  {
    name: 'Google LLC',
    country: '',
    activities: 'Cloud service provider',
    personalData: 'No',
  },
  {
    name: 'Intercom, Inc.',
    country: 'United States',
    activities: 'Customer Support',
    personalData: 'Yes',
  },
  {
    name: 'Mailgun Technologies, Inc.',
    country: 'United States',
    activities: 'Email communications',
    personalData: 'No',
  },
  {
    name: 'Medstack',
    country: 'Canada',
    activities: 'DevOps and server hosting',
    personalData: 'Yes',
  },
  {
    name: 'Notion',
    country: 'United States',
    activities: 'Documentation',
    personalData: 'No',
  },
  {
    name: 'Slack Technologies, Inc.',
    country: 'United States',
    activities: 'Internal communications',
    personalData: 'No',
  },
  {
    name: 'Stripe, Inc.',
    country: '',
    activities: 'Payments',
    personalData: 'Yes',
  },
  {
    name: 'The Rocket Science Group LLC (Mailchimp)',
    country: 'United States',
    activities: 'Email communications',
    personalData: 'No',
  },
  {
    name: 'Twilio',
    country: '',
    activities: 'Video, voice and text communications',
    personalData: 'No',
  },
  {
    name: 'VM Farms',
    country: 'Canada',
    activities: 'DevOps and server hosting',
    personalData: 'Yes',
  },
  {
    name: 'Zendesk, Inc.',
    country: 'United States',
    activities: 'Customer Support',
    personalData: 'Yes',
  },
];

const renderTableRow = data => (
  <tr>
    <td>{data.name}</td>
    <td>{data.country}</td>
    <td>{data.activities}</td>
    <td>{data.personalData}</td>
  </tr>
);

class Subprocessors extends Component {
  constructor(props) {
    super(props);
    this.state = { showInfo: false, password: '', error: '' };
  }

  onSubmit = event => {
    event.preventDefault();

    axios
      .post(`${SERVER_URL}/api/v2/subprocessors/request_access`, {
        password: this.state.password,
      })
      .then(() => {
        this.setState({ showInfo: true });
      })
      .catch(() => {
        this.setState({ showInfo: false, error: 'Incorrect Password' });
      });
  };

  render() {
    return (
      <div>
        {!this.state.showInfo && (
          <div className="subprocessors-page" style={{ height: '100%' }}>
            <img
              style={{ marginBottom: '40px' }}
              className="ink-logo"
              title="Inkblot"
              src={inkblotLogoDark}
              alt="Logo"
            />
            <h1>Inkblot Subprocessors </h1>
            <form className="password-container" onSubmit={this.onSubmit}>
              <label>Password</label>
              <input
                style={{
                  marginTop: '10px',
                  color: '#212121',
                  fontSize: '16px',
                }}
                onChange={event =>
                  this.setState({ password: event.target.value })
                }
                type="password"
                name="password"
              />
              <p>
                To request for the password to access this information, please
                contact your Account Manager.
              </p>
              {this.state.error && (
                <p style={{ color: 'red', margin: 0 }}>{this.state.error}</p>
              )}
              <button type="submit" onClick={this.onSubmit}>
                Submit
              </button>
            </form>
          </div>
        )}
        {this.state.showInfo && (
          <div className="subprocessors-page">
            <img
              style={{ marginBottom: '40px' }}
              className="ink-logo"
              title="Inkblot"
              src={inkblotLogoDark}
              alt="Logo"
            />

            <h1>Introduction</h1>
            <h2>Effective: Aug 18, 2020</h2>
            <p>
              To support delivery of our Services, Inkblot Technologies, Inc.
              may engage and use data processors with access to certain Customer
              Data (each, a "**Subprocessor**"). This page provides important
              information about the identity, location and role of each
              Subprocessor.
              <br />
              <br />
              Inkblot currently uses third party Subprocessors to provide
              infrastructure services, and to help us provide customer support
              and secure email and video communications.
              <br />
              <br />
              Prior to engaging any third party Subprocessor, Inkblot performs
              diligence to evaluate their privacy, security and confidentiality
              practices, and executes an agreement implementing its applicable
              obligations.
              <br />
              <br />
              Inkblot may use the following Subprocessors to host Customer Data,
              provide other infrastructure that helps with delivery of our
              Services, or to perform other Service functions:
            </p>
            <h1>Inkblot Subprocessors</h1>
            <table style={{ marginBottom: 200 }}>
              <tr>
                <th>Entity Name</th>
                <th>Entity Country</th>
                <th>Subprocessing Activities</th>
                <th>Personal Identifying Data</th>
              </tr>
              {_.map(subprocessorData, data => renderTableRow(data))}
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default Subprocessors;
